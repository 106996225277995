import i18next from "../i18n"
import { strUcFirst } from "../utils/index";

export enum DISPLAY_TYPES {
  EVERYONE = "Tout le monde",
  SELECTED = "Sélectionné",
  NOBODY = "Personne",
}

export enum SETTINGS_COLLAPSES {
  PROFILE = "Profil",
  THEME = "Thème",
  PRIVACY = "Confidentialité",
  SECURITY = "Sécurité",
  HELP = "Aide",
}
export interface DisplayOpt {
  label: string;
  value: DISPLAY_TYPES;
}

export const DisplayTypes: Array<DisplayOpt> = [
  { label: strUcFirst(i18next.t('constants.everyone')), value: DISPLAY_TYPES.EVERYONE },
  { label: strUcFirst(i18next.t('constants.nobody')), value: DISPLAY_TYPES.NOBODY },
  { label: strUcFirst(i18next.t('constants.selected')), value: DISPLAY_TYPES.SELECTED },
];

export enum STATUS_TYPES {
  ACTIVE = "Active",
  AWAY = "Loin",
  DO_NOT_DISTURB = "Ne pas déranger",
}
