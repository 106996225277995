import React from "react";

import i18next from "../i18n"
import { strUcFirst } from "../utils/index";

interface EmptyStateContactsProps {
  searchedText: string;
}
const EmptyStateContacts = ({ searchedText }: EmptyStateContactsProps) => {
  return (
    <div className="rounded p-4 text-center">
      <i className="bx bx-info-circle fs-1 mb-3" />
      <div>{strUcFirst(i18next.t('components.no_results_found_for.contacts'))} "{searchedText}".</div>
    </div>
  );
};

export default EmptyStateContacts;
