import React from "react";

import i18next from "../../../i18n"
import { strUcFirst } from "../../../utils/index";

interface StatusProps {
  about: string;
}
const Status = ({ about }: StatusProps) => {
  return (
    <div className="text-muted pt-4">
      <h5 className="font-size-11 text-uppercase">{strUcFirst(i18next.t('others.status'))} :</h5>
      <p className="mb-4">{about ? about : "-"}</p>
    </div>
  );
};

export default Status;
