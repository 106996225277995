import React from "react";
import { Link } from "react-router-dom";

import i18next from "../../../i18n"
import { strUcFirst } from "../../../utils/index";

const Help = () => {
  return (
    <div className="accordion-body">
      <ul className="list-group list-group-flush">
        <li className="list-group-item py-3 px-0 pt-0">
          <h5 className="font-size-13 mb-0">
            <Link to="#" className="text-body d-block">
            {strUcFirst(i18next.t('pages.dashboard.settings.faqs'))}
            </Link>
          </h5>
        </li>
        <li className="list-group-item py-3 px-0">
          <h5 className="font-size-13 mb-0">
            <Link to="#" className="text-body d-block">
            {strUcFirst(i18next.t('pages.dashboard.settings.contact'))}
            </Link>
          </h5>
        </li>
        <li className="list-group-item py-3 px-0 pb-0">
          <h5 className="font-size-13 mb-0">
            <Link to="#" className="text-body d-block">
            {strUcFirst(i18next.t('pages.dashboard.settings.terms'))}
            </Link>
          </h5>
        </li>
      </ul>
    </div>
  );
};

export default Help;
