import React from "react";
import { Button, Col, Row } from "reactstrap";

import i18next from "../../../i18n"
import { strUcFirst } from "../../../utils/index";

const Welcome = () => {
  return (
    <React.Fragment>
      <div className="chat-welcome-section">
        <Row className="w-100 justify-content-center">
          <Col xxl={5} md={7}>
            <div className="p-4 text-center">
              <div className="avatar-xl mx-auto mb-4">
                <div className="avatar-title bg-soft-primary rounded-circle">
                  <i className="bx bxs-message-alt-detail display-4 text-primary m-0"></i>
                </div>
              </div>
              <h4>{strUcFirst(i18next.t('pages.dashboard.conversation_user.welcome.welcome_msg'))} {strUcFirst(i18next.t('name_app'))}</h4>
              <p className="text-muted mb-4">
                {strUcFirst(i18next.t('pages.dashboard.conversation_user.welcome.welcome_description'))}
              </p>
              <Button type="button" className="btn btn-primary w-lg">
              {strUcFirst(i18next.t('pages.dashboard.conversation_user.welcome.welcome_btn'))}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Welcome;
