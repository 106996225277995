import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
} from "reactstrap";

import i18next from "../i18n"
import { strUcFirst } from "../utils/index";

/*interface DataTypes {
  email: string | null;
  name: string | null;
  message: string | null;
}*/

interface CreateContactTypes {
  first_name: string | null;
  last_name: string | null;
  email: string | null;
  phone: string | null;
  location: string | null;
}
interface InviteContactModalProps {
  isOpen: boolean;
  onClose: () => void;
  onInvite: (data: any) => void;
}
const InviteContactModal = ({
  isOpen,
  onClose,
  onInvite,
}: InviteContactModalProps) => {
  /*
  data input handeling
  */
  const [data, setData] = useState<CreateContactTypes>({
    first_name: null,
    last_name: null,
    email: null,
    phone: null,
    location: null,
  });
  useEffect(() => {
    setData({
      first_name: null,
      last_name: null,
      email: null,
      phone: null,
      location: null,
    });
  }, []);

  const onChangeData = (field: "first_name" | "last_name" | "email" | "phone" | "location", value: string) => {
    let modifiedData: CreateContactTypes = { ...data };
    if (value === "") {
      modifiedData[field] = null;
    } else {
      modifiedData[field] = value;
    }
    setData(modifiedData);
  };

  /*
  validation
  */
  // const [valid, setValid] = useState<boolean>(false);
  // useEffect(() => {
  //   if (data.email !== null && data.message !== null && data.name !== null) {
  //     setValid(true);
  //   } else {
  //     setValid(false);
  //   }
  // }, [data]);
  return (
    <Modal isOpen={isOpen} toggle={onClose} tabIndex={-1} centered scrollable >
      <ModalHeader  toggle={onClose} className="bg-primary">
      <div className="modal-title-custom text-white font-size-16 ">
      {strUcFirst(i18next.t('components.invite_contact_modal.create_contact'))}
      </div>
      </ModalHeader>
      <ModalBody className="p-4">
        <Form>
          <div className="mb-3">
            <Label htmlFor="AddContactModalemail-input" className="form-label">
            {strUcFirst(i18next.t('form.first_name'))}
            </Label>
            <Input
              type="text"
              className="form-control"
              id="AddContactModalemail-input"
              placeholder={strUcFirst(i18next.t('form.first_name_input'))}
              value={data["first_name"] || ""}
              onChange={(e: any) => {
                onChangeData("first_name", e.target.value);
              }}
            />
          </div>
          <div className="mb-3">
            <Label htmlFor="AddContactModalemail-input" className="form-label">
              {strUcFirst(i18next.t('form.last_name'))}
            </Label>
            <Input
              type="text"
              className="form-control"
              id="AddContactModalemail-input"
              placeholder={strUcFirst(i18next.t('form.last_name_input'))}
              value={data["last_name"] || ""}
              onChange={(e: any) => {
                onChangeData("last_name", e.target.value);
              }}
            />
          </div>
          <div className="mb-3">
            <Label htmlFor="AddContactModalemail-input" className="form-label">
            {strUcFirst(i18next.t('form.email'))}
            </Label>
            <Input
              type="email"
              className="form-control"
              id="AddContactModalemail-input"
              placeholder={strUcFirst(i18next.t('form.email_input'))}
              value={data["email"] || ""}
              onChange={(e: any) => {
                onChangeData("email", e.target.value);
              }}
            />
          </div>
          <div className="mb-3">
            <Label htmlFor="AddContactModalemail-input" className="form-label">
            {strUcFirst(i18next.t('form.phone'))}
            </Label>
            <Input
              type="tel"
              className="form-control"
              id="AddContactModalemail-input"
              placeholder={strUcFirst(i18next.t('form.phone_input'))}
              value={data["phone"] || ""}
              onChange={(e: any) => {
                onChangeData("phone", e.target.value);
              }}
            />
          </div>
          <div className="mb-3">
            <Label htmlFor="AddContactModalname-input" className="form-label">
            {strUcFirst(i18next.t('form.location'))}
            </Label>
            <Input
              type="text"
              className="form-control"
              id="AddContactModalname-input"
              placeholder={strUcFirst(i18next.t('form.location_input'))}
              value={data["location"] || ""}
              onChange={(e: any) => {
                onChangeData("location", e.target.value);
              }}
            />
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button type="button" color="link" className="btn" onClick={onClose}>
        {strUcFirst(i18next.t('button.close'))}
        </Button>
        <Button
          type="button"
          color="primary"
          // disabled={!valid}
          onClick={() => onInvite(data)}
        >
          {strUcFirst(i18next.t('button.invite'))}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default InviteContactModal;
